import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/how-to-healthy-baking.jpg'
import styles from './post-grid.module.css'

const HowToHealthyBaking = () => {
    return (
        <div className={styles.wrapper}>
            <SEO
                title={'Jak na zdravé pečení?'}
                description={
                    'Aktivně se zajímáte o zdravý životní styl, snažíte se snížit příjem cukru, nebo si jen chcete zaexperimentovat v kuchyni? Zkuste si upéct něco “zdravého”!'
                }
            />
            <Container>
                <Breadcrumbs style={{ margin: '24px 0' }}>
                    <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
                    <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
                    <BreadcrumbsItem link="/blog/how-to-healthy-baking">
                        Jak na zdravé pečení?
                    </BreadcrumbsItem>
                </Breadcrumbs>
            </Container>
            <Container>
                <div className={styles.postGrid}>
                    <div>
                        <Hero title="Jak na zdravé pečení?" date="23.05.2023" />
                        <div>
                            <p className={styles.postText}>
                                Aktivně se zajímáte o<b> zdravý životní styl</b>, snažíte se snížit příjem cukru, nebo si jen chcete zaexperimentovat v kuchyni? Zkuste si upéct něco “zdravého”!
                            </p>

                            <img
                                src={img1}
                                className={styles.postImg}
                                style={{ marginBottom: '40px' }}
                                alt="vegetariánské jídlo"
                            />
                            <p className={styles.postText}>Proč zdravého v uvozovkách? <b>Nic jako zdravé a nezdravé jídlo totiž neexistuje</b>, vždy jde jen o množství, které spořádáte. Pokud však pečete pravidelně, určitě vašemu jídelníčku jen prospěje, když ho čas od času trochu <b>pozměníte a zkusíte nějakou odlehčenou variantu</b>. Podívejme se tedy, jak na to.</p>

                            <p className={styles.postText}>Co použít, aby bylo pečení zdravější? </p>
                            <p className={styles.postText}>Pokud se snažíte zhubnout anebo vylepšit svůj jídelníček, <b>nemusíte se vzdávat své oblíbené pochoutky, ale můžete je upravit</b> tak, aby byly <b>výživnější</b> a stále chutné. Zkuste se zaměřit na použití ingrediencí, které jsou pro naše tělo <b>prospěšné</b> a méně kalorické než tradiční ingredience, jako je například hladká mouka či cukr.
                            </p>
                            <p className={styles.postText}>Jak nahradit bílou mouku?</p>
                            <p className={styles.postText}>
                                Místo klasické bílé mouky můžete zkusit například mouku <b>celozrnnou</b>, která je vyrobena <b>z celého pšeničného zrna</b>, narozdíl od mouky bílé proto obsahuje i <b>obalové vrstvy a klíček</b>. Díky tomu obsahuje <b>větší množství nerozpustné vlákniny, ale také vitaminů skupiny B a vitaminu E, některých minerálních látek a tuku</b>. Díky tomu má vyšší výživovou hodnotu, ale také se <b>rychleji kazí</b>, měla by se proto rychleji spotřebovat. Také dávejte pozor na její skladování. Můžete vyzkoušet také <b>grahamovou mouku</b>, která obsahuje obaly, ale nemusí obsahovat klíček, je zde proto menší riziko žluknutí a dá se déle skladovat</p>
                            <p className={styles.postText}>Dálé můžete zvolit například <b>špaldovou, ideálně celozrnnou</b> mouku, která má větší množství bílkovin, než bílá mouka, obsahuje vitaminy skupiny B, minerální látky, jako jsou vápník, fosfor, draslík, hořčík a zinek, některé esenciální aminokyseliny a také vyšší obsah vlákniny, antioxidantů a tuků. Také má <b> podobnou technologickou zpracovatelnost</b>, což znamená, že můžete špaldovou moukou úplně nahradit tu bílou.</p>
                            <p className={styles.postText}>Také můžete vyzkoušet <b>jiné druhy</b>, jako například mouku žitnou, ječnou, kokosovou či mandlovou. Ty jsou však vhodné spíše <b>na zpestření</b> a do receptu by se měli přidávat spíše z části, ideálně z <b>jedné třetiny</b>, mají totiž jiné vlastnosti, než bílá mouka, výsledek by proto nemusel vyjít úplně podle vašich představ. </p>
                            <p className={styles.postText}>Další možností je mouku uplně vyřadit a nahradit ji mletými <b>ovesnými</b> (nebo jinými) <b>vločkami</b>.</p>
                            <p className={styles.postText}>Jak nahradit cukr? </p>
                            <p className={styles.postText}>Co se cukru týče, obecně je <b>lepší jej ubrat</b> a zvykat na nesladkou chuť, než za něj hledat náhražky. Ideálně ho <b>uberte cca o jednu třetinu</b>. Pokud se totiž budete snažit nahradit cukr například sirupy, moc si nepomůžete, <b>celková energetická hodnota bude totiž téměř stejná</b>. Výjimkou je <b>čekankový sirup</b>, což je rozpustná vláknina z kořene čekanky, ten má nižší kalorickou hodnotu a prebiotické vlastnosti. Má ovšem úplně jiné technologické vlastnosti, než bílý cukr, pokud ho tedy chcete použít, <b>nahraďte</b> jím (opět) jen <b>jednu třetinu cukru</b>.</p>
                            <p className={styles.postText}>Můžete využít i <b>nekalorická sladidla</b>, u kterých si však musíte zjistit, zda je jejich sladivost nižší než u cukru (erytritol) nebo vyšší (většina sladidel). Odradit váš může také jejich <b>cena nebo pachuť</b>, kterou mohou způsobit. Některá sladidla také nesnesou tepelnou úpravu (aspartam).</p>
                            <p className={styles.postText}>Sladidlo můžete jednoduše nahradit i <b>ovocem</b>. Ideální je například <b>banán</b>, který má vhodnou konzistenci a dostatečně vám pokrm osladí. Využít ale můžete jakékoliv ovoce, co máte rádi. </p>
                            <p className={styles.postText}>Co dalšího nahradit?</p>
                            <p className={styles.postText}>Pokud používáte smetanu, měli byste vědět, že je <b>dost kalorická a obsahuje vysoké množství tuku</b>. Jako zdravější alternativu můžete použít nízkotučné mléko, jogurt nebo třeba tvaroh, podle toho, co zrovna pečete. Díky <b>tvarohu</b> získáte dezert, který tělu <b>dodá bílkoviny a zároveň vás zasytí.</b></p>
                            <p className={styles.postText}>Pokud poléváte dezert <b>čokoládou</b>, místo mléčné či bílé sáhněte raději po nějaké <b>kvalitní hořké</b>, s obsahem kakaa ideálně <b>okolo 60-70 %</b>.</p>
                            <p className={styles.postText}>Pokud chcete mít dezert obohacen o <b>vlákninu</b>, přidejte do těsta namleté <b>ořechy</b> nebo <b>semínka</b>, které, mimo vlákniny, dodají i <b>zdravé tuky.</b> Přidejte také ovoce, které vám navíc pokrm příjemně <b>osladí</b>.
                            </p>
                            <p className={styles.postText}>Co se <b>másla</b> týče, nemusíte se bát, že by se při pečení <b>přepalovalo</b>. Ikdyž nastavíte troubu na 175 stupňů celsia, což je bod zakouření másla, vnitřek těsta se na takovouto teplotu <b> nikdy nedostane</b>. Ačkoliv máslo není potravina, co byste měli mít na talíři denně, <b>čas od času jeho konzumace nevadí</b> a do bábovky či koláče jej můžete v klidu použít. I máslo se však <b>dá nahradit</b>. Místo něj můžete použít například <b>avokádo, řecký jogurt nebo dokonce jablečné či dýňové pyré.</b> </p>

                            <p className={styles.postText}>
                                Závěrem
                            </p>
                            <p className={styles.postText}>
                                Nápady na zdravé pečení jsou <b>nekonečné</b> a lze je přizpůsobit dle libosti a chuti. Snažte se omezit především množství cukru a tuku a navýšit množství bílkovin, vlákniny a dalších kvalitních surovin,které vás <b>nejen potěší, ale i zasytí.</b> Vyzkoušejte si nějaké recepty na zdravé pečení a uvidíte, že mohou být <b>stejně chutné jako ty klasické</b>. Jen s <b>větší výživovou hodnotou</b>. Užijte si zdravou a zároveň sladkou chuť bez výčitek svědomí!
                            </p>
                            <p className={styles.postText}>PS: Nezapomínejte, že i <b>klasické pečení</b> je čas od času <b>úplně v pořádku!</b> Jak jsem zmínila, nejde tolik o samotné potraviny, jako o to, kolik jich sníte. Takže všeho s mírou!
                            </p>
                            <p className={styles.postText}>Muffiny s mrkví a ořechy
                            </p>
                            <p className={styles.postText}>Na závěr pro vás mám <b>recept</b> na zdravější verzi muffinů s mrkví a ořechy, které jsou skvělé jako svačina nebo snídaně.
                            </p>

                            <h5 className={styles.postTitle}>
                                Na muffiny potřebujete:

                            </h5>
                            <p className={styles.postText}>
                                1 a 1/2 šálku celozrnné mouky <br /> 1/2 šálku jemných ovesných vloček <br />2 lžičky prášku do pečiva
                                <br /> 1 lžičku skořice
                                <br />1/2 lžičky mletého zázvoru
                                <br /> 1/4 lžičky soli
                                <br /> 2 vejce
                                <br />1/2 šálku rozpuštěného kokosového oleje
                                <br /> 1/2 šálku medu
                                <br /> 1/2 šálku nasekaných vlašských ořechů
                                <br />1/2 šálku sušených brusinek
                                <br />1 a 1/2 šálku nastrouhané mrkve
                                <br /> 1/4 šálku mandlových plátků (na ozdobení)
                            </p>
                            <h5 className={styles.postTitle}>Postup:</h5>
                            <p className={styles.postText}>
                                Předehřejte troubu na 180°C a připravte muffinové formy. Ve velké míse smíchejte mouku, ovesné vločky, prášek do pečiva, skořici, zázvor a sůl. V jiné míse rozmíchejte vejce, kokosový olej a med. Smíchejte mokré a suché ingredience a přidejte ořechy, brusinky a nastrouhanou mrkev. Nalijte těsto do muffinových forem a ozdobte mandlovými plátky. Pečte v předehřáté troubě asi 20-25 minut, dokud nejsou muffiny zlatavé a pevné na dotek. Nechte vychladnout a podávejte, ideálně s porcí <b>jogurtu či tvarohu a ovocem.</b> Dobrou chuť!

                            </p>
                            <p className={styles.postText}>
                                Autorka: Ing. Kristina Heřmanová
                            </p>
                        </div>
                    </div>
                    <PostSidebar />
                </div>
            </Container>
        </div>
    )
}

export default HowToHealthyBaking
